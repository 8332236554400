import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { sendInvitation } from "../../../utils/https/StaffManagement/mutation";
import { queryClient } from "../../../utils/https";

import { roleOptions } from "../../../data/role-options";

import Button from "../../UI/Buttons/Button";

import styles from "./InviteForm.module.scss";
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { RiSendPlaneFill } from "react-icons/ri";

function InviteForm() {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(2);

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: sendInvitation,
    onSuccess: () => queryClient.invalidateQueries(["adminList"]),
  });

  const submitHandler = (e) => {
    e.preventDefault();

    const invitationData = { email, role };

    mutate(invitationData);

    setEmail("");
    setRole(2);
  };

  return (
    <form className={styles["invite-form"]}>
      <label htmlFor="inviteEmail" className={styles["invite-label"]}>
        INVITE BY EMAIL
      </label>
      {isError && <p>{error.message}</p>}
      <div className={styles["invite-input-group"]}>
        <InputGroup className={styles["select-wrapper"]}>
          <Form.Control
            id="inviteEmail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <DropdownButton
            variant="light"
            title={roleOptions.find((option) => option.value === role).name}
            id="input-group-dropdown-2"
            align="end"
          >
            {roleOptions.map((role) => (
              <Dropdown.Item
                key={role.value}
                onClick={() => setRole(role.value)}
              >
                {role.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </InputGroup>

        <Button
          variant="primary"
          className={styles["invite-button"]}
          onClick={submitHandler}
        >
          {isPending ? (
            "Sending..."
          ) : (
            <>
              <RiSendPlaneFill className={styles["send-icon"]} /> Send Invite
            </>
          )}
        </Button>
      </div>
    </form>
  );
}

export default InviteForm;
