import { useState } from "react";
import Button from "../../components/UI/Buttons/Button";
import EditableContent from "../../components/UI/EditableContent";
import SectionWrapper from "../../components/UI/Layout/SectionWrapper";

import styles from "./SendNotifications.module.scss";
import useCheckAuth from "../../hooks/useCheckAuth";

function SendNotificationsPage() {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  // Check if user is logged in. If not, redirect to login page.
  useCheckAuth();

  const submitHandler = () => {
    console.log({
      title,
      body,
    });

    // TODO: Send data to API
  };

  const discardHandler = () => {
    setTitle("");
    setBody("");
  };

  return (
    <>
      <SectionWrapper
        title="Notification"
        description="You can send a note to all promoters here."
      >
        <EditableContent
          inputId="notification-title"
          inputLabel="Title"
          inputPlaceholder="Notification Title"
          textareaId="notification-body"
          textareaLabel="Content"
          textareaClassName={styles.textarea}
          title={title}
          body={body}
          onChangeTitle={setTitle}
          onChangeBody={setBody}
        />
        <p>{500 - body.length} characters remaining.</p>
      </SectionWrapper>

      <div className={styles["action-buttons"]}>
        <Button variant="primary" onClick={submitHandler}>
          Update
        </Button>
        <Button variant="secondary" onClick={discardHandler}>
          Discard
        </Button>
      </div>
    </>
  );
}

export default SendNotificationsPage;
