import SectionWrapper from "../../components/UI/Layout/SectionWrapper";
import useCheckAuth from "../../hooks/useCheckAuth";

export default function MarketingMaterialManagementPage() {
  // Check if user is logged in. If not, redirect to login page.
  useCheckAuth();

  return (
    <SectionWrapper
      title="Marketing Material Management"
      description="You can manage marketing materials here."
    ></SectionWrapper>
  );
}
