// import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../store/auth-context";
import { Link } from "react-router-dom";

import NavItem from "./NavItem";
import NavGroup from "./NavGroup";

import styles from "./NavBar.module.scss";
import { FaCircleQuestion } from "react-icons/fa6";
import { IoMdLogOut } from "react-icons/io";

export default function NavBar({ onClose }) {
  // const [activeSection, setActiveSection] = useState(null);

  // const toggleSection = (section) => {
  //   setActiveSection(section === activeSection ? null : section);
  // };

  const authCtx = useContext(AuthContext);

  const contentManagementLinks = [
    { name: "Home Page Edit", path: "/content-management/home" },
    { name: "About Us Page Edit", path: "/content-management/about-us" },
    { name: "FAQ Page Edit", path: "/content-management/faq" },
    {
      name: "Terms & Agreement Page Edit",
      path: "/content-management/terms-and-agreement",
    },
  ];

  const promoterAccountsLinks = [
    {
      name: "Access to Accounts",
      path: "/promoter-accounts-management/access-to-accounts",
    },
    {
      name: "Send Notifications",
      path: "/promoter-accounts-management/send-notifications",
    },
  ];

  const systemSettingLinks = [
    {
      name: "Staff Management",
      path: "/system-setting/staff-management",
    },
    {
      name: "Venue Staff Management",
      path: "/system-setting/venue-staff-management",
    },
    {
      name: "Stripe Account",
      path: "/system-setting/stripe-account",
    },
  ];

  return (
    <nav className={styles.nav}>
      <div className={styles["links-group"]}>
        {/* Content Management */}
        <NavGroup
          title="Content Management"
          links={contentManagementLinks}
          path="content-management"
          onClose={onClose}
        />

        {/* Promoter Accounts Management */}

        <NavGroup
          title="Promoter Accounts Management"
          links={promoterAccountsLinks}
          path="promoter-accounts-management"
          onClose={onClose}
        />

        {/* Promoter Approval */}

        <NavItem
          title="Promoter Approval"
          path="promoter-approval"
          onClose={onClose}
        />

        {/* Reports */}

        <NavItem title="Reports" path="reports" onClose={onClose} />

        {/* System Setting */}

        <NavGroup
          title="System Setting"
          links={systemSettingLinks}
          path="system-setting"
          onClose={onClose}
        />

        {/* Marketing Material Management */}

        <NavItem
          title="Marketing Material Management"
          path="marketing-material-management"
          onClose={onClose}
        />

        <div className={styles["other-links"]}>
          <Link to="/faq" onClick={onClose}>
            <FaCircleQuestion />
            <span>FAQ</span>
          </Link>
          {authCtx.user && (
            <Link to="/login" onClick={() => authCtx.logout()}>
              <IoMdLogOut />
              <span>Log Out</span>
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
}
