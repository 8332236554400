import AutoCompleteSearch from "../UI/AutoComplete/AutoCompleteSearch";
import {
  filterByEvent,
  filterByPerformance,
  filterByPromoter,
  filterByVenue,
} from "../../utils/filter-suggestion";
import CategoryFilter from "./CategoryFilter";
import DateFilter from "./DateFilter";
import SaleTimeFilter from "./SaleTimeFilter";

import styles from "./ReportFilterForm.module.scss";
import ActionButtons from "../UI/Buttons/ActionButtons";

const SectionWrapper = ({ children, title }) => {
  return (
    <section className={styles["filter-section"]}>
      <h4 className={styles["section-title"]}>{title}</h4>
      {children}
    </section>
  );
};

export default function ReportFilterForm({
  selectedFilters,
  isSaleTimeAllDay,
  onAddSearchFilter,
  onRemoveSearchFilter,
  onSelectCategory,
  onSelectDateRange,
  onSelectSaleTime,
  onResetFilters,
  onToggleSaleTimeAllDay,
  onSubmit,
}) {
  const actionButtons = [
    {
      text: "Apply Filter",
      variant: "primary",
      onClick: onSubmit,
      className: styles["action-button"],
    },
    {
      text: "Discard",
      variant: "secondary",
      onClick: onResetFilters,
      className: styles["action-button"],
    },
  ];

  return (
    <div className={styles.container}>
      <h3 className={styles.heading}>Filter By</h3>
      <section className={styles["filter-section"]}>
        <AutoCompleteSearch
          label="Promoter"
          selectedItems={selectedFilters.promoter}
          placeholder="Search Promoters"
          onFilter={filterByPromoter}
          onAdd={onAddSearchFilter.bind(null, "promoter")}
          onRemove={onRemoveSearchFilter.bind(null, "promoter")}
        />
        <AutoCompleteSearch
          label="Event"
          selectedItems={selectedFilters.event}
          placeholder="Search Events"
          onFilter={filterByEvent}
          onAdd={onAddSearchFilter.bind(null, "event")}
          onRemove={onRemoveSearchFilter.bind(null, "event")}
        />
        <AutoCompleteSearch
          label="Venue"
          selectedItems={selectedFilters.venue}
          placeholder="Search Venues"
          onFilter={filterByVenue}
          onAdd={onAddSearchFilter.bind(null, "venue")}
          onRemove={onRemoveSearchFilter.bind(null, "venue")}
        />
        <AutoCompleteSearch
          label="Performance"
          selectedItems={selectedFilters.performance}
          placeholder="Search Performances or Enter the Code"
          onFilter={filterByPerformance}
          onAdd={onAddSearchFilter.bind(null, "performance")}
          onRemove={onRemoveSearchFilter.bind(null, "performance")}
        />
      </section>
      <SectionWrapper title="Category">
        <CategoryFilter
          selectedCategory={selectedFilters.category}
          onSelectCategory={onSelectCategory}
        />
      </SectionWrapper>
      <SectionWrapper title="Date Range">
        <DateFilter
          selectedRange={selectedFilters.selectedDateRange}
          onSelectRange={onSelectDateRange}
        />
      </SectionWrapper>
      <SectionWrapper title="Sale Time">
        <SaleTimeFilter
          selectedSaleTime={selectedFilters.saleTime}
          isSaleTimeAllDay={isSaleTimeAllDay}
          onSelectSaleTime={onSelectSaleTime}
          onToggleSaleTimeAllDay={onToggleSaleTimeAllDay}
        />
      </SectionWrapper>
      <ActionButtons
        buttons={actionButtons}
        className={styles["action-buttons"]}
      />
    </div>
  );
}
