import { useEffect, useState } from "react";

import { DUMMY_EVENTS_LIST } from "../../DUMMY_DATA/DUMMY_EVENTS";
import { DUMMY_PROMOTERS_LIST } from "../../DUMMY_DATA/DUMMY_PROMOTERS";

import TabSelector from "../../components/UI/TabSelector";
import SectionWrapper from "../../components/UI/Layout/SectionWrapper";
import PromoterList from "../../components/PromoterAccountsManagement/AccessToAccounts/AccessToAccounts/PromoterList";
import EventList from "../../components/PromoterAccountsManagement/AccessToAccounts/AccessToAccounts/EventList";
import SearchSort from "../../components/UI/Search/SearchSort";

import styles from "./AccessToAccounts.module.scss";
import useCheckAuth from "../../hooks/useCheckAuth";

function AccessToAccountsPage() {
  const [activeTab, setActiveTab] = useState("Promoters");
  const [sortOption, setSortOption] = useState("A-Z");
  const [searchTerm, setSearchTerm] = useState("");

  // Check if user is logged in. If not, redirect to login page.
  useCheckAuth();

  const tabOptions = ["Promoters", "Events"];

  useEffect(() => {
    setSearchTerm("");
  }, [activeTab]);

  return (
    <SectionWrapper
      title="Access to Promoters' Accounts"
      description="You can have access to promoters' accounts here."
      className={styles["section-wrapper"]}
    >
      <SearchSort
        sortOption={sortOption}
        setSortOption={setSortOption}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <div>
        <TabSelector
          tabOptions={tabOptions}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className={styles["tab-selector"]}
        />
        {activeTab === "Promoters" ? (
          <PromoterList
            list={DUMMY_PROMOTERS_LIST}
            sortOption={sortOption}
            searchTerm={searchTerm}
          />
        ) : (
          <EventList
            list={DUMMY_EVENTS_LIST}
            showPromoterName={true}
            sortOption={sortOption}
            searchTerm={searchTerm}
          />
        )}
      </div>
    </SectionWrapper>
  );
}

export default AccessToAccountsPage;
