import SectionWrapper from "../../components/UI/Layout/SectionWrapper";
import useCheckAuth from "../../hooks/useCheckAuth";

export default function VenueStaffManagementPage() {
  // Check if user is logged in. If not, redirect to login page.
  useCheckAuth();

  return (
    <SectionWrapper
      title="Venue Staff Management"
      description="You can manage venue staff here."
    ></SectionWrapper>
  );
}
