import { useState } from "react";
import useCheckAuth from "../../hooks/useCheckAuth";

import { DUMMY_CM_TERMS } from "../../DUMMY_DATA/DUMMY_CM";

import SectionWrapper from "../../components/UI/Layout/SectionWrapper";
import EditableContent from "../../components/UI/EditableContent";
import ActionButtons from "../../components/UI/Buttons/ActionButtons";

import styles from "./TermsAgreement.module.scss";

function TermsContent() {
  const [prevData, setPrevData] = useState(DUMMY_CM_TERMS);

  const [termsOfUse, setTermsOfUse] = useState(DUMMY_CM_TERMS.termsOfUse);
  const [privacyAgreement, setPrivacyAgreement] = useState(
    DUMMY_CM_TERMS.privacyAgreement
  );

  // Check if user is logged in. If not, redirect to login page.
  useCheckAuth();

  const submitHandler = () => {
    console.log({
      termsOfUse,
      privacyAgreement,
    });

    // TODO: Send data to API
  };

  const discardHandler = () => {
    setTermsOfUse(prevData.termsOfUse);
    setPrivacyAgreement(prevData.privacyAgreement);
  };

  const actionButtons = [
    {
      text: "Discard",
      variant: "secondary",
      onClick: discardHandler,
      className: styles["action-button"],
    },
    {
      text: "Update",
      variant: "primary",
      onClick: submitHandler,
      className: styles["action-button"],
    },
  ];

  return (
    <>
      <SectionWrapper
        title="Terms and Agreement"
        description="You can change the content of Terms and Agreement here."
        className={styles["content-wrapper"]}
      >
        <EditableContent
          textareaId="terms-of-use"
          textareaLabel="Terms of Use"
          textareaClassName={styles.textarea}
          title={null}
          body={termsOfUse}
          onChangeTitle={null}
          onChangeBody={setTermsOfUse}
        />
        <EditableContent
          textareaId="privacy-agreement"
          textareaLabel="Privacy Agreement"
          textareaClassName={styles.textarea}
          title={null}
          body={privacyAgreement}
          onChangeTitle={null}
          onChangeBody={setPrivacyAgreement}
        />
      </SectionWrapper>

      <ActionButtons
        buttons={actionButtons}
        className={styles["action-buttons"]}
      />
    </>
  );
}

export default TermsContent;
