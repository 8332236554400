import { useContext, useEffect } from "react";
import { AuthContext } from "../../store/auth-context";
import { useLoaderData, useNavigate } from "react-router-dom";

export default function InvitationHandler() {
  const data = useLoaderData();
  const authCtx = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      authCtx.login(data);
      navigate("/content-management/home", { replace: true });
    }
  }, [data, authCtx, navigate]);

  return <h1>Loading ...</h1>;
}

export async function loader() {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  const res = await fetch(
    `${process.env.REACT_APP_ADMIN_API}/authentication/invitation-callback?code=${code}`,
    { credentials: "include" }
  );
  const data = await res.json();

  if (!res.ok) {
    throw new Response(data.error, { status: 400 });
  }

  return data;
}
