import Button from "../../components/UI/Buttons/Button";
import SectionWrapper from "../../components/UI/Layout/SectionWrapper";
import useCheckAuth from "../../hooks/useCheckAuth";

export default function StripeAccountPage() {
  // Check if user is logged in. If not, redirect to login page.
  useCheckAuth();

  return (
    <SectionWrapper
      title="Stripe Account"
      description="You can have access to Stripe account here."
    >
      <Button>Access to Stripe Account</Button>
    </SectionWrapper>
  );
}
