import axios from "axios";
import { useState } from "react";
import { useActionData, useSubmit } from "react-router-dom";

import Button from "../../components/UI/Buttons/Button";
import Header from "../../components/UI/Layout/Header";
import LoginFormWrapper from "../../components/UI/Layout/LoginFormWrapper";

import styles from "./ResetPassword.module.scss";
import Form from "react-bootstrap/Form";

export default function ResetPasswordPage() {
  const [email, setEmail] = useState("rooonaldjp@gmail.com");

  const submit = useSubmit();

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    submit(
      { email },
      { method: "POST", encType: "application/json", action: "/reset-password" }
    );
  };

  const data = useActionData();

  return (
    <>
      <Header />
      <main className={styles["authentication-page"]}>
        <LoginFormWrapper title="Reset Password">
          {data && <p>{data.message}</p>}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" onClick={resetPasswordHandler}>
            Reset Password
          </Button>
        </LoginFormWrapper>
      </main>
    </>
  );
}

export async function action({ request }) {
  const data = await request.json();

  console.log(data);

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ADMIN_API}/authentication/reset-password?email=${data.email}`,

      {
        withCredentials: true,
      }
    );

    return res.data;
  } catch (error) {
    console.log(error.response.data.error);
    throw new Response(error.response.data.error, { status: error.status });
  }
}
