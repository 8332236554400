import Form from "react-bootstrap/Form";

import styles from "./LoginFormWrapper.module.scss";

export default function LoginFormWrapper({ children, title }) {
  return (
    <Form className={styles["login-form"]}>
      <h1>{title}</h1>
      {children}
    </Form>
  );
}
