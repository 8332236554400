import useCheckAuth from "../../hooks/useCheckAuth";
import { useQuery } from "@tanstack/react-query";
import {
  fetchAdminList,
  fetchInvitationList,
} from "../../utils/https/StaffManagement/query";

import SectionWrapper from "../../components/UI/Layout/SectionWrapper";
import InviteForm from "../../components/SystemSetting/StaffManagement/InviteForm";
import AdminList from "../../components/SystemSetting/StaffManagement/AdminList";

import styles from "./StaffManagement.module.scss";
import { Blocks as BlocksLoader } from "react-loader-spinner";
import InvitationList from "../../components/SystemSetting/StaffManagement/InvitationList";

export default function StaffManagementPage() {
  const {
    data: adminList,
    isPending: isAdminListPending,
    error: adminListError,
    isError: isAdminListError,
  } = useQuery({
    queryKey: ["adminList"],
    queryFn: fetchAdminList,
  });

  const {
    data: invitationList,
    isPending: isInvitationListPending,
    error: invitationListError,
    isError: isInvitationListError,
  } = useQuery({
    queryKey: ["invitationList"],
    queryFn: fetchInvitationList,
  });

  // Check if user is logged in. If not, redirect to login page.
  useCheckAuth();

  console.log(invitationList);
  console.log(invitationList);

  return (
    <SectionWrapper
      title="Staff Management"
      description="You can manage team access here."
      className={styles["section-wrapper"]}
    >
      <InviteForm />
      {isInvitationListPending ? (
        <BlocksLoader
          height="50"
          width="50"
          color="#27aae1"
          ariaLabel="blocks-loading"
          visible={true}
        />
      ) : !isInvitationListError ? (
        <InvitationList list={invitationList} />
      ) : (
        <p>{invitationListError.message}</p>
      )}
      {isAdminListPending ? (
        <BlocksLoader
          height="50"
          width="50"
          color="#27aae1"
          ariaLabel="blocks-loading"
          visible={true}
        />
      ) : !isAdminListError ? (
        <AdminList list={adminList} />
      ) : (
        <p>{adminListError.message}</p>
      )}
    </SectionWrapper>
  );
}
