import { useNavigate, useRouteError } from "react-router-dom";

import Header from "../components/UI/Layout/Header";
import Button from "../components/UI/Buttons/Button";

import styles from "./Error.module.scss";
import { TbFaceIdError } from "react-icons/tb";

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();

  console.dir(error);

  return (
    <>
      <Header />
      <main className={styles["error-content"]}>
        <div className={styles.heading}>
          <TbFaceIdError /> <h1>ERROR</h1>
        </div>

        <p>{error.data || error.message}</p>
        <Button variant="primary" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </main>
    </>
  );
}
