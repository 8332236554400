import { useEffect, useState } from "react";

import DatePicker from "../UI/DatePicker";
import ToggleButton from "../UI/Buttons/ToggleButton";

import styles from "./SaleTimeFilter.module.scss";

function SaleTimeFilter({
  selectedSaleTime,
  isSaleTimeAllDay,
  onSelectSaleTime,
  onToggleSaleTimeAllDay,
}) {
  const [startTime, setStartTime] = useState(selectedSaleTime.startTime);
  const [endTime, setEndTime] = useState(selectedSaleTime.endTime);

  useEffect(() => {
    setStartTime(selectedSaleTime.startTime);
    setEndTime(selectedSaleTime.endTime);
  }, [selectedSaleTime]);

  const checkHandler = () => {
    onToggleSaleTimeAllDay((prev) => !prev);
    onSelectSaleTime({
      ...selectedSaleTime,
      startTime: new Date(selectedSaleTime.startTime.setHours(0, 0, 0)),
      endTime: new Date(selectedSaleTime.endTime.setHours(23, 59, 59)),
    });
  };

  const changeTimeHandler = (timeType, time) => {
    onSelectSaleTime({
      ...selectedSaleTime,
      [timeType]: time,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles["date-picker-group"]}>
        <p>From</p>
        <div>
          <DatePicker
            date={startTime}
            onChangeDate={(startTime) =>
              changeTimeHandler("startTime", startTime)
            }
          />
        </div>
        <p>To</p>
        <div>
          <DatePicker
            date={endTime}
            onChangeDate={(endTime) => changeTimeHandler("endTime", endTime)}
          />
        </div>
      </div>

      <div className={styles["date-picker-group"]}>
        <ToggleButton
          label="All Day"
          isChecked={isSaleTimeAllDay}
          onCheck={() => checkHandler()}
        />
        <div>
          <DatePicker
            date={startTime}
            onChangeDate={(startTime) =>
              changeTimeHandler("startTime", startTime)
            }
            isDisabled={isSaleTimeAllDay}
            isTimeOnly={true}
            options={{
              showTimeSelect: true,
              showTimeSelectOnly: true,
              timeIntervals: 15,
              timeCaption: "Time",
              dateFormat: "h:mm aa",
              disabled: isSaleTimeAllDay,
            }}
          />
        </div>
        <div>
          <DatePicker
            date={endTime}
            onChangeDate={(endTime) => changeTimeHandler("endTime", endTime)}
            isDisabled={isSaleTimeAllDay}
            isTimeOnly={true}
            options={{
              showTimeSelect: true,
              showTimeSelectOnly: true,
              timeIntervals: 15,
              timeCaption: "Time",
              dateFormat: "h:mm aa",
              disabled: isSaleTimeAllDay,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SaleTimeFilter;
