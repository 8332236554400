import { useState } from "react";

import ReactQuill from "react-quill";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import DOMPurify from "dompurify";

import SectionWrapper from "../components/UI/Layout/SectionWrapper";
import ActionButtons from "../components/UI/Buttons/ActionButtons";

import styles from "./TextFormatter.module.scss";

export default function TextFormatterPage() {
  const [quillContent, setQuillContent] = useState("");

  const [sunContent, setSunContent] = useState("");

  const editorChange = (c) => {
    setSunContent(
      DOMPurify.sanitize(c, {
        USE_PROFILES: { html: true },
      })
    );
  };

  const submitQuillHandler = () => {
    console.log(
      "Quill: Will be stored in the database as JSON object: ",
      JSON.stringify(quillContent)
    );
  };

  const submitSunHandler = () => {
    console.log("Sun: Will be stored in the database as HTML: ", sunContent);
  };

  const handleImageUpload = (
    targetImgElement,
    index,
    state,
    imageInfo,
    remainingFilesCount
  ) => {
    console.log(targetImgElement, index, state, imageInfo, remainingFilesCount);
  };

  const actionButtons = [
    {
      text: "Submit Quill",
      variant: "secondary",
      onClick: submitQuillHandler,
      className: styles["action-button"],
    },
    {
      text: "Submit Sun",
      variant: "primary",
      onClick: submitSunHandler,
      className: styles["action-button"],
    },
  ];

  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ color: [] }, "bold", "italic", "underline"], // dropdown with defaults from theme
    [{ align: [] }],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    ["image"],
    ["link"],
    ["clean"], // remove formatting button
  ];

  return (
    <>
      <SectionWrapper title={"Sun Editor"}>
        <SunEditor
          setOptions={{
            height: 200,
            buttonList: buttonList.complex, // Or Array of button list, eg. [['font', 'align'], ['image']]
          }}
          onChange={editorChange}
          onImageUpload={handleImageUpload}
        />
      </SectionWrapper>
      <SectionWrapper title={"Display Area: Sun Editor"}>
        {sunContent && (
          <div
            dangerouslySetInnerHTML={{ __html: sunContent }}
            className="sun-editor-editable"
          />
        )}
      </SectionWrapper>
      <ActionButtons
        buttons={actionButtons}
        className={styles["action-buttons"]}
      />
      <SectionWrapper title={"Quill Editor"}>
        <ReactQuill
          theme="snow"
          modules={{ toolbar: toolbarOptions }}
          formats={[
            "header",
            "bold",
            "italic",
            "underline",
            "list",
            "color",
            "font",
            "align",
            "link",
            "image",
          ]}
          value={quillContent}
          onChange={(content, delta, source, editor) =>
            setQuillContent(editor.getContents())
          }
        />
      </SectionWrapper>

      <SectionWrapper title={"Display Area: Quill Editor"}>
        {quillContent && (
          <div
            dangerouslySetInnerHTML={{
              __html: new QuillDeltaToHtmlConverter(
                quillContent.ops,
                {}
              ).convert(),
            }}
            className="ql-editor"
          />
        )}
      </SectionWrapper>
    </>
  );
}
