import { useMutation } from "@tanstack/react-query";
import { revokeInvitation } from "../../../utils/https/StaffManagement/mutation";
import { queryClient } from "../../../utils/https";

import styles from "./InvitationList.module.scss";
import Button from "../../UI/Buttons/Button";

export default function InvitationList({ list }) {
  const { mutate } = useMutation({
    mutationFn: revokeInvitation,
    onSuccess: () => queryClient.invalidateQueries(["invitationList"]),
  });

  const revokeHandler = (invitationId) => {
    mutate(invitationId);
  };

  return (
    <ul>
      <h3>Pending Invitation</h3>
      {list?.length === 0 && <p>No pending invitation</p>}
      {list?.map((invitation) => (
        <li key={invitation.invitationId} className={styles["invitation-item"]}>
          <p>{invitation.email}</p>
          <Button
            variant="primary"
            onClick={() => revokeHandler(invitation.invitationId)}
          >
            Revoke
          </Button>
        </li>
      ))}
    </ul>
  );
}
