import { roleOptions } from "../../../data/role-options";

import DefaultUserIcon from "../../UI/DefaultUserIcon";

import styles from "./AdminItem.module.scss";
import { Dropdown } from "react-bootstrap";

export default function AdminItem({ admin, isPending, onChangeRole }) {
  return (
    <li key={admin.workosId} className={styles["admin-item"]}>
      <div className={styles["admin-info"]}>
        {admin.profilePictureUrl ? (
          <img
            src={admin.profilePictureUrl}
            alt=""
            className={styles["admin-profile-picture"]}
          />
        ) : (
          <DefaultUserIcon
            firstLetter={admin?.firstName[0]?.toUpperCase()}
            secondLetter={admin?.lastName[0]?.toUpperCase()}
          />
        )}
        <span>{`${admin.firstName} ${admin.lastName}`}</span>
      </div>
      {admin.role === 1 ? (
        <div className={styles["role-admin"]}>Root Admin</div>
      ) : (
        <Dropdown>
          <Dropdown.Toggle
            variant="primary"
            id="dropdown-basic"
            className={styles["role-toggle"]}
          >
            {isPending
              ? "Saving..."
              : roleOptions.find((role) => role.value === admin.role).name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {roleOptions.map((role) => (
              <Dropdown.Item
                key={role.value}
                id={role.value}
                onClick={() => onChangeRole(admin.workosId, role.value)}
              >
                {role.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </li>
  );
}
