import { useState } from "react";
import useCheckAuth from "../../hooks/useCheckAuth";

import { DUMMY_CM_HOME } from "../../DUMMY_DATA/DUMMY_CM";

import HeroImage from "../../components/ContentManagement/Home/HeroImage";
import CallToAction from "../../components/ContentManagement/Home/CallToAction/CallToAction";
import IconContents from "../../components/ContentManagement/Home/IconContents/IconContents";
import Quote from "../../components/ContentManagement/Home/Quote";
import ActionButtons from "../../components/UI/Buttons/ActionButtons";

import styles from "./Home.module.scss";

export default function ContentManagementHome() {
  const [prevData, setPrevData] = useState(DUMMY_CM_HOME);
  const [data, setData] = useState(DUMMY_CM_HOME);

  // Check if user is logged in. If not, redirect to login page.
  useCheckAuth();

  const submitHandler = () => {
    console.log(data);

    // TODO: Send data to API
  };

  const discardHandler = () => {
    setData(prevData);
  };

  const actionButtons = [
    {
      text: "Discard",
      variant: "secondary",
      onClick: discardHandler,
      className: styles["action-button"],
    },
    {
      text: "Update",
      variant: "primary",
      onClick: submitHandler,
      className: styles["action-button"],
    },
  ];

  return (
    <>
      <HeroImage image={data.HERO} onUpdate={setData} />
      <CallToAction CTA={data.CTA} onUpdate={setData} />
      <IconContents iconContents={data.ICONS} onUpdate={setData} />
      <Quote quote={data.QUOTE} onUpdate={setData} />
      <ActionButtons
        buttons={actionButtons}
        className={styles["action-buttons"]}
      />
    </>
  );
}
