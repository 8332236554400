import axios from "axios";

/**
 * Updates the admin roles by sending a PATCH request to the API.
 *
 * @param {Object} request - The request object containing the submitted updated roles.
 * @return {Object} The response data from the API containing a success/error message.
 */
export const updateAdminRoles = async (updatedAdmins) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_ADMIN_API}/users/update-admin-roles`,
      updatedAdmins,
      {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      }
    );
    return res.data;
  } catch (err) {
    const error = new Error(err.response.data.error);
    error.status = err.status;
    console.log(error);
    throw error;
  }
};

/**
 * Sends an admin invitation to the specified recipient.
 *
 * @param {Object} invitationData - The data containing the email and the role of the invitee.
 * @return {Object} The response data from the API.
 */
export const sendInvitation = async (invitationData) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ADMIN_API}/authentication/send-invitation`,
      invitationData,
      {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      }
    );

    return res.data;
  } catch (err) {
    const error = new Error(err.response.data.error);
    error.status = err.status;
    console.log(error);
    throw error;
  }
};

/**
 * Revokes an invitation using the invitation id by sending a request to the API.
 *
 * @param {string} invitationId - The id of the invitation to be revoked.
 * @return {Object} The response data from the API.
 */
export const revokeInvitation = async (invitationId) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ADMIN_API}/authentication/revoke-invitation`,
      invitationId,
      {
        withCredentials: true,
        headers: { "Content-Type": "text/plain" },
      }
    );

    return res.data;
  } catch (err) {
    const error = new Error(err.response.data.error);
    error.status = err.status;
    console.log(error);
    throw error;
  }
};
