import { useState } from "react";

import SectionWrapper from "../../components/UI/Layout/SectionWrapper";
import RequestList from "../../components/PromoterApproval/RequestList";

import styles from "./PromoterApproval.module.scss";
import SearchBox from "../../components/UI/Search/SearchBox";
import useCheckAuth from "../../hooks/useCheckAuth";

export default function PromoterApprovalPage() {
  const [searchTerm, setSearchTerm] = useState("");

  // Check if user is logged in. If not, redirect to login page.
  useCheckAuth();

  return (
    <SectionWrapper
      title="Requests"
      description="You can review the approval requests from promoters here."
      className={styles["section-wrapper"]}
    >
      <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      <RequestList searchTerm={searchTerm} />
    </SectionWrapper>
  );
}
