import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../store/auth-context";

import Cookies from "js-cookie";

export default function useCheckAuth() {
  // Check if user is logged in. If not, redirect to login page.
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const isLoggedIn = Cookies.get("isLoggedIn");

  useEffect(() => {
    if (!isLoggedIn && !authCtx.user) {
      navigate("/login", { replace: true });
    }
  }, [authCtx.user, navigate, isLoggedIn]);
}
