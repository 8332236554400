import { useState } from "react";
import useCheckAuth from "../../hooks/useCheckAuth";

import { DUMMY_CM_ABOUTUS } from "../../DUMMY_DATA/DUMMY_CM";

import EditableContent from "../../components/UI/EditableContent";
import SectionWrapper from "../../components/UI/Layout/SectionWrapper";

import styles from "./AboutUs.module.scss";
import ActionButtons from "../../components/UI/Buttons/ActionButtons";

function AboutUsPage() {
  const { aboutUs, testimonial } = DUMMY_CM_ABOUTUS;

  const [prevData, setPrevData] = useState(DUMMY_CM_ABOUTUS);

  const [aboutUsTitle, setAboutUsTitle] = useState(aboutUs.title);
  const [aboutUsBody, setAboutUsBody] = useState(aboutUs.body);
  const [testimonialTitle, setTestimonialTitle] = useState(testimonial.title);
  const [testimonialBody, setTestimonialBody] = useState(testimonial.body);

  // Check if user is logged in. If not, redirect to login page.
  useCheckAuth();

  const submitHandler = () => {
    console.log({
      aboutUsTitle,
      aboutUsBody,
      testimonialTitle,
      testimonialBody,
    });

    // TODO: Send data to API
  };

  const discardHandler = () => {
    setAboutUsTitle(prevData.aboutUs.title);
    setAboutUsBody(prevData.aboutUs.body);
    setTestimonialTitle(prevData.testimonial.title);
    setTestimonialBody(prevData.testimonial.body);
  };

  const actionButtons = [
    {
      text: "Discard",
      variant: "secondary",
      onClick: discardHandler,
      className: styles["action-button"],
    },
    {
      text: "Update",
      variant: "primary",
      onClick: submitHandler,
      className: styles["action-button"],
    },
  ];

  return (
    <>
      <SectionWrapper
        title="About Us"
        description="You can change the content of the About-us page here."
      >
        <EditableContent
          inputId="about-us-title"
          inputLabel="Title"
          inputPlaceholder="About Us"
          textareaId="about-us-body"
          textareaLabel="Body Paragraph"
          textareaClassName={styles.textarea}
          title={aboutUsTitle}
          body={aboutUsBody}
          onChangeTitle={setAboutUsTitle}
          onChangeBody={setAboutUsBody}
        />
      </SectionWrapper>
      <SectionWrapper
        title="Testimonial"
        description="You can change the content of the testimonial here."
      >
        <EditableContent
          inputId="testimonial-title"
          inputLabel="Title"
          inputPlaceholder="Testimonial"
          textareaId="testimonial-body"
          textareaLabel="Body Paragraph"
          textareaClassName={styles.textarea}
          title={testimonialTitle}
          body={testimonialBody}
          onChangeTitle={setTestimonialTitle}
          onChangeBody={setTestimonialBody}
        />
      </SectionWrapper>

      <ActionButtons
        buttons={actionButtons}
        className={styles["action-buttons"]}
      />
    </>
  );
}

export default AboutUsPage;
