import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../store/auth-context";

import MobileNavBar from "../NavBar/MobileNavBar/MobileNavBar";

import styles from "./Header.module.scss";
import { IoMenu } from "react-icons/io5";
import DefaultUserIcon from "../DefaultUserIcon";

export default function Header() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    const closeMenu = () => {
      setShowMobileMenu(false);
    };

    window.addEventListener("resize", closeMenu);
    return () => window.removeEventListener("resize", closeMenu);
  }, []);

  return (
    <header className={styles.header}>
      <div className={styles["logo-container"]}>
        <Link to="/">
          <img
            src="/assets/logo/logo-blue-dark-bg.svg"
            alt="EventLinx"
            className={styles.logo}
          />
        </Link>
      </div>
      {showMobileMenu && (
        <MobileNavBar onClose={() => setShowMobileMenu(false)} />
      )}
      <div className={styles["icon-container"]}>
        {authCtx.user && (
          <button>
            {authCtx.user.profilePictureUrl ? (
              <img
                src={authCtx.user.profilePictureUrl}
                alt={authCtx.user.firstName}
                className={styles["profile-picture"]}
              />
            ) : (
              <DefaultUserIcon
                firstLetter={authCtx.user?.firstName[0]}
                secondLetter={authCtx.user?.lastName[0]}
              />
            )}
          </button>
        )}
        <button className={styles["menu-btn"]}>
          <IoMenu onClick={() => setShowMobileMenu(true)} />
        </button>
      </div>
    </header>
  );
}
