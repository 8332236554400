import axios from "axios";

/**
 * Fetches the list of administrators from the API.
 *
 * @return {Array} The response data from the API containing the list of admin.
 */
export const fetchAdminList = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ADMIN_API}/users/get-admin-list`,
      { withCredentials: true }
    );
    return res.data;
  } catch (err) {
    const error = new Error(err.response.data.error);
    error.status = err.status;
    console.log(error);
    throw error;
  }
};

/**
 * Fetches the list of invitations from the API.
 *
 * @return {Array} The response data from the API containing the list of invitations.
 * @throws {Error} If there is an error retrieving the data from the API.
 */
export const fetchInvitationList = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ADMIN_API}/authentication/get-invitation-list`,
      { withCredentials: true }
    );
    return res.data;
  } catch (err) {
    const error = new Error(err.response.data.error);
    error.status = err.status;
    console.log(error);
    throw error;
  }
};
