import axios from "axios";
import { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

export const AuthContext = createContext({
  user: {},
  login: () => {},
  logout: () => {},
});

export default function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);

  // Fetch user data from API, set it to user state.
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_ADMIN_API}/users/get-admin-info`,
          {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
          }
        );

        if (!res.data) {
          Cookies.remove("isLoggedIn");
          setUser(null);
          return;
        }

        setUser(res.data);
      } catch (error) {
        Cookies.remove("isLoggedIn");
        setUser(null);
      }
    };

    // Only fetch user data if user is logged in (i.e. "isLoggedIn" cookie is set)
    const isLoggedIn = Cookies.get("isLoggedIn");
    if (isLoggedIn) {
      fetchUser();
    }
  }, []);

  // Login Handler
  const loginHandler = (user) => {
    Cookies.set("isLoggedIn", "true", { expires: 7 });
    setUser(user);
  };

  // Logout Handler
  const logoutHandler = () => {
    axios.get(`${process.env.REACT_APP_ADMIN_API}/authentication/logout`, {
      withCredentials: true,
    });

    Cookies.remove("isLoggedIn");
    setUser(null);
  };

  const context = { user, login: loginHandler, logout: logoutHandler };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}
