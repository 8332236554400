import { useEffect, useState } from "react";

import styles from "./DateFilter.module.scss";
import RadioButtons from "../UI/Buttons/RadioButtons";

import DatePicker from "../UI/DatePicker";

function DateFilter({ selectedRange, onSelectRange }) {
  const isSpecificDateSelected =
    selectedRange !== "week" &&
    selectedRange !== "month" &&
    selectedRange !== "year";

  const [date, setDate] = useState(
    isSpecificDateSelected ? selectedRange : new Date()
  );

  useEffect(() => {
    if (!isSpecificDateSelected) {
      return;
    }

    setDate(selectedRange);
  }, [selectedRange, isSpecificDateSelected]);

  const radioButtons = [
    {
      value: "week",
      text: "This Week",
    },
    { value: "month", text: "This Month" },
    { value: "year", text: "This Year" },
  ];

  const changeDateHandler = (date) => {
    // setDate(date);
    onSelectRange(date);
  };

  return (
    <div>
      <RadioButtons
        buttons={radioButtons}
        currentState={selectedRange}
        onChangeValue={onSelectRange}
        className={styles["date-button-group"]}
      />

      <DatePicker
        date={date}
        onChangeDate={changeDateHandler}
        isSelected={isSpecificDateSelected}
      />
    </div>
  );
}

export default DateFilter;
