import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/auth-context";
import {
  useActionData,
  useNavigate,
  useNavigation,
  useSubmit,
} from "react-router-dom";

import Button from "../../components/UI/Buttons/Button";
import ToggleButton from "../../components/UI/Buttons/ToggleButton";
import Form from "react-bootstrap/Form";
import Header from "../../components/UI/Layout/Header";
import LoginFormWrapper from "../../components/UI/Layout/LoginFormWrapper";

import styles from "./Login.module.scss";
import { FcGoogle } from "react-icons/fc";

export default function LoginPage() {
  const [email, setEmail] = useState("rooonald@gmail.com");
  const [password, setPassword] = useState("Test-123");
  const [keepSignedIn, setKeepSignedIn] = useState(false);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const submit = useSubmit();

  const { state } = useNavigation();

  const submitFormHandler = async (e) => {
    e.preventDefault();

    submit(
      { email, password },
      { method: "POST", encType: "application/json", action: "/login" }
    );
  };

  const googleLoginHandler = () => {
    window.location.href = `${process.env.REACT_APP_ADMIN_API}/authentication/google-auth`;
  };

  const userData = useActionData();

  useEffect(() => {
    if (userData) {
      authCtx.login(userData);
      navigate("/content-management/home", { replace: true });
    }
  }, [userData, authCtx, navigate]);

  return (
    <>
      <Header />
      <main className={styles["authentication-page"]}>
        <LoginFormWrapper title="Login">
          <div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Text
                className="text-muted"
                onClick={() => navigate("/reset-password")}
              >
                Forget your password?
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
          </div>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <ToggleButton
              label="Keep me signed in"
              isChecked={keepSignedIn}
              onCheck={() => setKeepSignedIn((prev) => !prev)}
            />
          </Form.Group>
          <Button variant="primary" onClick={submitFormHandler}>
            {state === "submitting" ? "Signing In ..." : "Sign In"}
          </Button>
          <Button variant="secondary" onClick={googleLoginHandler}>
            <FcGoogle /> Continue with Google
          </Button>
        </LoginFormWrapper>
      </main>
    </>
  );
}

export async function action({ request }) {
  const data = await request.json();

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ADMIN_API}/authentication/login`,
      {
        email: data.email,
        password: data.password,
      },
      {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      }
    );

    if (!res.data) {
      throw new Response("Failed to login", { status: 403 });
    }

    // Cookies.set("isLoggedIn", "true");

    return res.data;
  } catch (error) {
    console.log(error.response.data.error);
    throw new Response(error.response.data.error, { status: error.status });
  }
}
