import styles from "./DefaultUserIcon.module.scss";

export default function DefaultUserIcon({ firstLetter, secondLetter }) {
  return (
    <div className={styles["default-user-icon"]}>
      {firstLetter}
      {secondLetter}
    </div>
  );
}
